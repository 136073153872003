import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import Accordion from 'components/Accordion/Accordion';
import './FAQs.scss';
import { HashLink as Link } from 'react-router-hash-link';

import Banner from 'components/Banner/Banner';
import ModuleBanner from 'assets/images/campaign-2023/banner/banner-headline.png';
import ModuleBanner_2x from 'assets/images/campaign-2023/banner/banner-headline@2x.png';
import ModuleBannerMob from 'assets/images/campaign-2023/banner/banner-headline-mob.png';
import ModuleBannerMob_2x from 'assets/images/campaign-2023/banner/banner-headline-mob@2x.png';

export default (props) => {
  let defaultFaq = { section: 1, index: 0 };
  if (window.location.hash.length) {
    let values = window.location.hash.replace('#', '').split('-');
    defaultFaq = {
      section: parseInt(values[0]),
      index: parseInt(values[1])
    }
  }

  const [accordion, setAccordion] = useState(defaultFaq);

  const handleAccordionClick = (index) => {
    if (index.index === accordion.index &&
      index.section === accordion.section) index = { section: 0, index: 0 };
    setAccordion(index);
  }

  const renderFaqs = (section, faqs) => {
    return faqs.map((faq, index) => {
      let id = { section, index };
      let key = `${id.section}-${id.index}`;

      return (
        <Accordion
          id={key}
          key={key}
          open={`${accordion.section}-${accordion.index}` === key}
          onClick={handleAccordionClick.bind(this, id)}
          title={faq.question}
          answer={faq.answer}>
        </Accordion>
      )
    })
  }

  return <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>FAQs | Cadbury</title>
    </Helmet>
    <section className="layout layout-general layout-wrapper">

      <Banner
        image={ModuleBanner}
        image2x={ModuleBanner_2x}
        imageMob={ModuleBannerMob}
        imageMob2x={ModuleBannerMob_2x}
        alt={"Theme park ballons"}
      />

      <div className="layout-container layout-container--slim layout-container--center">
        <h1 className="type-h2 type-h2--gold">FAQ<span className="lowercase">s</span></h1>
        <div className="faqs">
          <div className="faqs__section">


            <h2 className="type-h3 type-h3--gold">General promotional questions</h2>
            {
              renderFaqs(1, [{
                question: "1.	What do I get with my promotional ticket pack?",
                answer: `
                <ul class="secondary">
                  <li>
                    <p><strong>Cadbury Big Ticket Savings (up to 50% off online priced tickets) - Chocolate Multipacks , Selection Boxes & Biscuits 2024</strong></p>
                    <p>The Cadbury Big Ticket Savings offer entitles the voucher holder to 10-50% off for up to 4 Adult and Child online priced entry tickets to one of the participating Merlin Attractions. Discount is dependant on attraction and day type chosen (super off peak, off peak, peak, super peak), from a minimum of 10% of super peak, up to a maximum of 50% on select super off peak dates. Tickets must be booked in advance online at <a href="https://fun.cadbury.co.uk" target="_blank" rel="noopener noreferrer">fun.cadbury.co.uk</a>. Valid until 30th June 2025.</p>
                  </li>
                  <li>
                    <p><strong>Cadbury Big Ticket Savings (up to 50% off) - Chocolate Multipacks 2023</strong></p>
                    <p>The Cadbury Big Ticket Savings offer entitles the voucher holder to up to 50% off up to 4 'On The Day' full priced Adult and Children entry tickets at selected Merlin Entertainments attractions. Discount depends on attraction period and time chosen, from a min of 25% off in super peak to a maximum of 55% off in super off peak. Tickets must be booked in advance online at <a href="https://fun.cadbury.co.uk" target="_blank" rel="noopener noreferrer">fun.cadbury.co.uk</a> valid until 31st July 2024.</p>
                  </li>
                  <li>
                    <p><strong>Cadbury Big Ticket Savings (up to 50% off) - Seasonal Chocolate & Biscuits 2023</strong></p>
                    <p>The Cadbury Big Ticket Savings offer entitles the voucher holder to up to 50% off up to 4 'On The Day' full priced Adult and Children entry tickets at selected Merlin Entertainments attractions. Discount depends on attraction period and time chosen, from a min of 25% off in super peak to a maximum of 55% off in super off peak. Tickets must be booked in advance online at <a href="https://fun.cadbury.co.uk" target="_blank" rel="noopener noreferrer">fun.cadbury.co.uk</a> valid until 31st December 2024.</p>
                  </li>
                </ul>
                  
                `}, {
                question: "2.	Which attractions are included?",
                answer: `
                  <ul>
                    <li>	Alton Towers Resort	</li>
                    <li>	Chessington World of Adventures Resort	</li>
                    <li>	Thorpe PARK	</li>
                    <li>	The LEGOLAND&#174; Windsor Resort	</li>
                    <li>	Warwick Castle	</li>
                    <li>	The Bear Grylls Adventure (valid for High Ropes, Climb, Axe Throwing, Archery and Shooting activities only)	</li>
                    <li>	Shrek's Adventure! London	</li>
                    <li>	Madame Tussauds (London or Blackpool)	</li>
                    <li>	The Dungeons (London, York, Edinburgh, or Blackpool Tower)	</li>
                    <li>	The Blackpool Tower Eye	</li>
                    <li>	The Blackpool Tower Eye Circus	</li>
                    <li>	The Lastminute.com London Eye	</li>
                    <li>	LEGOLAND&#174; Discovery Centres (Manchester or Birmingham)	</li>
                    <li>	SEA LIFE Aquariums & Centres (Birmingham, Great Yarmouth, Loch Lomond, Blackpool, London, Brighton, Manchester, Scarborough, Hunstanton and Weymouth SEA LIFE Adventure Park).	</li>
                    <li>	Cadbury World (2024 packs only)	</li>
              
                  </ul>

                `}, {
                question: "3.	What products are included?",
                answer: `
                  <ul class="secondary">
                    <li>
                      <p><strong>Cadbury Big Ticket Savings (up to 50% off online priced tickets) - Chocolate Multipacks , Selection Boxes & Biscuits 2024</strong></p>
                      <p>Cadbury Chocolate Multipacks and Barny Soft Baked Bars including: Fudge 5 pack, Curly Wurly 5 pack, Freddo 5 pack, Freddo Caramel 5 pack, Dairy Milk 9 pack, Double Decker 9 pack, Wispa 9 pack, Little Bars 6 pack, Barny Soft Bake Bars.</p>
                      <p>Cadbury Christmas packs including: 89g Small Selection Box and 1445g Medium Santa Selection Box and Cadbury Snowy Fingers 115g. Oreo 154g Halloween pack.</p>
                    </li>
                    <li>
                      <p><strong>Cadbury Big Ticket Savings (up to 50% off) - Chocolate Multipacks 2023</strong></p>
                      <p>Fudge 5 pack, Curly Wurly 5 pack, Cadbury Dairy Milk Buttons 5 pack, Cadbury Dairy Milk Freddo 5 pack, Cadbury Dairy Milk Freddo Caramel 5 pack, Cadbury Dairy Milk Little Bar 6 pack, Cadbury Twirl 10pk, Cadbury Crunchie 9pk, Cadbury Wispa 9pk.</p>
                    </li>
                    <li>
                      <p><strong>Cadbury Big Ticket Savings (up to 50% off) - Seasonal Chocolate & Biscuits 2023</strong></p>
                      <p>Cadbury Snowy Fingers, Cadbury Festive Friends, Oreo Spooky, Cadbury Small Selection Box, Cadbury Medium Santa Selection Box, Cadbury Skeleton Fingers.</p>
                    </li>
                  </ul>
                `}, {
                question: "4.	How do I claim?",
                answer: `
                  <p><span class="faqs__uppercase">Step 1:</span> Go to <a href="https://fun.cadbury.co.uk" target="_blank" rel="noopener noreferrer">fun.cadbury.co.uk</a> and enter your barcode and batch code found on the reverse of your promotional pack (full details of where to locate these can be found online), along with your name and email address and enter required details.</p>
                  <p><span class="faqs__uppercase">Step 2:</span> Once your pack(s) have been validated you will be emailed your unique code and booking link.</p>
                  <p><span class="faqs__uppercase">Step 3:</span> Select your attraction and visit date, according to the discount level preferred (from those available), enter your unique code when prompted and complete the booking journey and pay for your entry tickets. Each code can only be used once.</p>
                  <p><span class="faqs__uppercase">Step 4:</span> Print/ download your entry tickets and head straight to the attraction on your booked date of visit, scan your tickets (on your phone or a print-out) under the barcode readers to enter the attraction.</p>
                  <p class="disclaimer">There are limited tickets available per attraction for each day, so we recommend planning ahead and booking early, especially for popular dates like weekends and school holidays, to avoid disappointment.</p>
                `}, {
                question: "5.	Do I have to book in advance?",
                answer: `
                  <p><strong class="faqs__uppercase">All promotions</strong></p>
                  <p>Yes. This is a requirement for all attractions and tickets booked.</p>
                  <p class="disclaimer">There are limited tickets available per attraction for each day, so we recommend planning ahead and booking early, especially for popular dates like weekends and school holidays, to avoid disappointment.</p>

                `}, {
                question: "6.	How long do I have to redeem this offer? ",
                answer: `
                  <ul class="secondary">
                    <li>
                      <p><strong>Cadbury Big Ticket Savings (up to 50% off online priced tickets) - Chocolate Multipacks , Selection Boxes & Biscuits 2024</strong></p>
                      <p>All packs above are valid until 30th June 2025.</p>
                    </li>
                    <li>
                      <p><strong>Cadbury Big Ticket Savings (up to 50% off) - Chocolate Multipacks 2023</strong></p>
                      <p>If you have bought a chocolate multipack promotion pack the offer is valid until 31st July 2024 at all participating attractions.</p>
                    </li>
                    <li>
                      <p><strong>Cadbury Big Ticket Savings (up to 50% off) - Seasonal Chocolate & Biscuits 2023</strong></p>
                      <p>If you have bought a seasonal biscuits pack or selection box, the offer is valid until 31st December 2024 at all participating attractions.</p>
                    </li>
                  </ul>
                `
              }, {
                question: "7.	When do the attractions open and close?",
                answer: `
                  <p>Opening dates and times vary per attraction, please check attraction websites for opening dates and times before your visit.</p>
                `
              }, {
                question: "8.	Are any dates excluded from the offer?",
                answer: `
                  <p>Yes, this offer <span class="faqs__uppercase">excludes</span> the following attraction close dates:</p>
                  
                  <p class="faqs__uppercase">Alton towers resort:</p>
                  <ul>
                    <li>4-6, 11-21, 25-28 November</li>
                    <li>2-5, 9-12, 22-28 December</li>
                  </ul>

                  <p class="faqs__uppercase">Chessington world of adventures resort:</p>
                  <ul>
                    <li>23, 30 April</li>
                    <li>7, 14 May</li>
                    <li>17, 24 September </li>
                    <li>1-2, 8-9, 15-16 October</li>
                    <li>4-8, 11-15, 18-22, 25-29 November</li>
                    <li>2-6, 9-12, 25-26 December</li>
                  </ul>

                  <p class="faqs__uppercase">Thorpe park:</p>
                  <ul>
                    <li>14-16 October</li>
                    <li>4-30 November</li>
                    <li>1-28 December</li>
                  </ul>

                  <p class="faqs__uppercase">The legoland&#174; windsor resort:</p>
                  <ul>
                    <li>17, 24 April</li>
                    <li>8, 15 May</li>
                    <li>25-26 September</li>
                    <li>2, 9 October </li>
                    <li>4-8, 11-22, 25-29 November</li>
                    <li>2-6, 9-12, 24-26 December</li>
                  </ul>

                  <p class="faqs__uppercase">Warwick castle resort, London Eye, Dungeons (York, Edinburgh, Blackpool and London), Shrek's Adventure London, Blackpool Tower Eye and Blackpool Tower Circus, Cadbury World, Legoland Discovery Centre (Birmingham and Manchester), Madame Tussauds London, SeaLife (London, Brighton,  Great Yarmouth, Hunstanton, Weymouth, Scarborough, Loch Lomond, Manchester, Birmingham, Blackpool), Bear Grylls Adventure:</p>
                  <ul>
                    <li>25 December</li>
                  </ul>
                                  `
              }, {
                question: "9.	How old do I need to be to visit the attractions?",
                answer: `
                  <p><strong class="faqs__uppercase">All promotions</strong><p>
                  <p>The age of an adult and child varies for each attraction. Children must be accompanied by a person aged 18 years and over at all attractions. Please check the attraction website or full terms and conditions for full details for your chosen attraction. No person aged 18 and over can enter the <span class="faqs__uppercase">Legoland</span>&#174; Discovery Centres (Manchester or Birmingham) without an accompanying child/ children aged 17 years or younger.</p>
                  
                  <p>Under 2's go free at most Merlin attractions apart from the below exceptions. For full details please check the attraction websites.</p>

                  <table>
                    <thead><tr><th>Attraction</th><th>Restriction</th></tr></thead>
                    <tbody>
                    <tr>
                      <td>
                        <ul>
                          <li>LEGOLAND&#174; Windsor Resort</li>
                        </ul>
                      </td>
                      <td>Under 90cm go free</td>
                    </tr>
                    <tr>
                      <td>
                        <ul>
                          <li>Chessington World of Adventures</li>
                        </ul>
                      </td>
                      <td>Under 90cm go free</td>
                    </tr>
                    <tr>
                    <td>
                      <ul>
                        <li>Alton Towers Resort </li>
                      </ul>
                    </td>
                    <td>Under 90cm go free</td>
                  </tr>
                  <tr>
                  <td>
                    <ul>
                      <li>Thorpe Park</li>
                    </ul>
                  </td>
                  <td>Under 1.2m go free</td>
                </tr>
                    </tbody>
                  </table>
                `
              }, {
                question: "10.	When can I use my code?",
                answer: `
                  <p>You can use your <span class="faqs__uppercase">up to 50% off</span> voucher any time after you have received the email with your unique code and booking link prior to the expiration date. We recommend booking sooner rather than later to avoid disappointment! </p>
                  <p class="disclaimer">There are limited tickets available per attraction for each day, so we recommend planning ahead and booking early, especially for popular dates like weekends and school holidays, to avoid disappointment.</p>
               
                `
              }, {
                question: "11.	Can I share my code with a friend?",
                answer: `
                <p>Yes, you can share these tickets with your friends, but remember each code can only be used <span class="faqs__uppercase">once</span> to book <span class="faqs__uppercase">up to 4 tickets</span> and will be taken in exchange for your tickets.</p>
                
                `
              }, {
                question: "12.	How many times can I claim?",
                answer: `
                <p>A barcode and batch code from a pack, and each unique code issued by Merlin, can only be used once.  An individual is only allowed to claim, and any one e-mail address may only be used to claim, a maximum of 5 unique codes per 7-day period.  </p>
                
                `
              }, {
                question: "13.	Can I use this code ticket alongside any other offer or promotion?",
                answer: `
                <p>No. This offer is not valid for use in conjunction with any other promotion, offer, reward/loyalty scheme or direct attraction booking.</p>
                
                `
              },{
                question: "14.	Why have I received 2 emails?",
                answer: `
                <p>There are different unique codes and booking links provided for both Chocolate Multipacks and Seasonal Chocolate & Biscuit Packs. You must use the specific 'Book Now' link in your email provided to book your tickets.</p>
                
                `
              }, {
                question: "15.	Can I take my code and book directly?",
                answer: `
                <p>No - you must book via the link provided in the email with your up to 50% off codes.</p>
                
                `
              }, {
                question: "16. I have entered my details but haven't received an email with my unique code, what should I do?",
                answer: `
                <p>Firstly, please check the email address you entered was correctly spelt.</p>
                <p>Secondly, please check your junk email folder.</p>
                <p>If still no success, please contact our <a href="https://contactus.mdlzapps.com/form?siteId=hyR6CJc3CK6Ii7pdDEiVDC7t6jWVjYW2" target="_blank" rel="noopener noreferrer">Customer Care</a>.</p>
                
                `
              }, {
                question: "17.	My barcode/ batch code is not being accepted, am I entering it correctly? ",
                answer: `
                <p>You must enter both the barcode and batch code from your promotional Cadbury product. The barcode number is the 13-digit code directly under the barcode which is scanned in store. The batch code is a 10-digit alpha-numerical code.</p>
                <p>If still no success, please contact our <a href="https://contactus.mdlzapps.com/form?siteId=hyR6CJc3CK6Ii7pdDEiVDC7t6jWVjYW2" target="_blank" rel="noopener noreferrer">Customer Care</a>.</p>
                
                `
              }, {
                question: "18.	My unique code isn't working, why? ",
                answer: `
                <p>Please ensure you are booking only through the link in your emails and not on the attraction website directly, enter the unique code when prompted to enter the â€˜offer code' in the booking journey. </p>
                <p>If the issue persists, please contact our <a href="https://contactus.mdlzapps.com/form?siteId=hyR6CJc3CK6Ii7pdDEiVDC7t6jWVjYW2" target="_blank" rel="noopener noreferrer">Customer Care</a>.</p>
                
                `
              }, {
                question: "19. Do I need to retain my packaging to claim?",
                answer: `
                <p>No but we recommend you retain proof of purchase.</p>
                
                `
              }, {
                question: "20.  Do I need internet?",
                answer: `
                <p>Yes - to claim you need to access <a href="https://fun.cadbury.co.uk" target="_blank" rel="noopener noreferrer">fun.cadbury.co.uk</a></p>
                
                `
              }, {
                question: "21.  Can I use my code to buy Fast Track passes? ",
                answer: `
                <p>No - This offer can only be used for standard single day entrance tickets.</p>
                
                `
              }, {
                question: "22.  Can I use my code to book accommodation?",
                answer: `
                <p>No - This promotional offer does not apply to any secondary attraction, events or hotel stays at any participating attractions.</p>
                
                `
              }, {
                question: "23.  Can I use my code to book parking for my visit?",
                answer: `
                <p>No - This offer is not valid for transport or car parking.</p>
                
                `
              }, {
                question: "24.  How do you use my data?",
                answer: `
                  <p>The Promoter shall comply with all applicable requirements of the Data Protection Act 2018, the General Data Protection Regulation 2016 and any successor legislation or other applicable law. The personal information entrants provide will be used by the Promoter for the purpose of conducting this promotion and in accordance with the Promoters' Data Privacy Notice which can be found <a href="https://eu.mondelezinternational.com/privacy-notice?sc_lang=en&siteId=hyR6CJc3CK6Ii7pdDEiVDC7t6jWVjYW2" target="_blank" rel="noopener noreferrer">here</a>.</p>
                  <p> The Promoter may disclose entrants' personal information to its contractors and agents to assist in conducting this Promotion (including any relevant promotional activity) or communicating with entrants.</p>
                `
              }



              ])
            }
          </div>

        </div>
      </div>
    </section>
  </>;
}